import React from "react";
import PropTypes from "prop-types";
import { TextInput } from "@narmi/design_system";
import ContextForm from "../ContextForm";

const DEFAULT_EMAIL_ERROR = "Please enter a valid email address.";

// basic email validation to confirm that input has a @ and there is at least one char on either side of @
// returning falsey counterintuitively means that no errors are found
export function validateEmail(value, disabled, errorMsg = DEFAULT_EMAIL_ERROR) {
  if (disabled) return null;
  if (!value) return errorMsg;

  // at least one character that's not a space or @ before the @
  // at least one character that's not a space or @ after the @, followed by a dot
  // at least one character that's not a space or @ after the dot
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Ensure email has no whitespace
  if (/\s/.test(value)) {
    return errorMsg;
  }

  if (!emailRegex.test(value)) {
    return errorMsg;
  }

  return null;
}

const EmailFormField = ({
  label,
  required = false,
  disabled,
  fieldStyle,
  className,
  errorLabel = DEFAULT_EMAIL_ERROR,
}) => {
  return (
    <ContextForm.Field
      required={required}
      validate={(e) => validateEmail(e, disabled, errorLabel)}
      style={fieldStyle}
      className={className}
    >
      <TextInput
        name="email"
        field="email"
        label={label || "Email"}
        type="email"
        data-test-element="email-input"
        disabled={disabled}
      />
    </ContextForm.Field>
  );
};
EmailFormField.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  fieldStyle: PropTypes.object,
  className: PropTypes.string,
  erroLabel: PropTypes.string,
};
export default EmailFormField;
