import { getDefaultNormalizer } from "@testing-library/dom";

// bidi fencing characters that wrap vars and selectors in fluent output
const BIDI_START_CHAR = "⁨";
const BIDI_END_CHAR = "⁩";

/**
 * @description Normalizer function for testing-library queries that handles Fluent vars
 * @usage
 * ```
 *  expect(
 *    screen.getByText("Text with fluent var", { normalizer: normalizeFluentVars })
 *  )
 * ```
 */
function normalizeFluentVars(str) {
  return getDefaultNormalizer()(str).replaceAll(BIDI_START_CHAR, "").replaceAll(BIDI_END_CHAR, "");
}

export default normalizeFluentVars;
