import React, { useEffect, useRef, useState } from "react";
import { JSONEditor } from "@json-editor/json-editor";
import cc from "classcat";
import { Button, Row, TextInput, Toggle } from "@narmi/design_system";
import style from "./JsonInput.module.css";
import "./NarmiTheme";
import useUserConfiguration from "./hooks/useUserConfiguration";

const Error = ({ error }) => {
  if (!error) return null;
  return (
    <div className={style.inputError}>
      <div className="fontSize--s narmi-icon-x-circle" />
      {error}
    </div>
  );
};

const JsonInputRichEditor = ({ onChange, error, setting, value, defaultValue }) => {
  const editorElement = useRef(null);
  const [editorReady, setEditorReady] = useState(false);
  let jsonError = null;

  try {
    JSON.parse(value);
    jsonError = null;
  } catch (e) {
    jsonError = "Error parsing JSON. Please use the simple editor to fix.";
  }

  useEffect(() => {
    if (jsonError == null) {
      const editor = new JSONEditor(editorElement.current, {
        schema: setting.json_schema,
        theme: "narmi",
        disable_collapse: true,
        prompt_before_delete: false,
        form_name_root: setting.human_facing_name,
        disable_edit_json: true,
        disable_properties: !setting.json_schema?.options?.allowAdditionalProperties,
        disable_array_reorder: true,
        disable_array_delete_all_rows: true,
        disable_array_delete_last_row: true,
        show_opt_in: !setting.json_schema?.options?.disableOptIn,
        startval: JSON.parse(value),
      });

      editor.on("ready", () => setEditorReady(true));

      editor.on("change", () => {
        const newValue = JSON.stringify(editor.getValue(), null, 4);
        onChange(null, newValue);
      });

      return () => {
        editor.destroy();
      };
    }

    return undefined;
  }, [editorElement, jsonError]);

  return (
    <>
      <div ref={editorElement} />
      <input type="hidden" name="setting_value" id="setting_value" value={value} />
      <Error error={error || jsonError} />
      {editorReady || jsonError ? (
        <>
          <div className="ui message">
            <div className="header">Previous Value</div>
            <p style={{ whiteSpace: "pre-wrap" }}>{defaultValue}</p>
          </div>
          <div className="ui message">
            <div className="header">New Value</div>
            <p style={{ whiteSpace: "pre-wrap" }}>{value}</p>
          </div>
        </>
      ) : null}
    </>
  );
};

const JsonInput = ({
  onChange,
  error,
  attemptedValue,
  setting,
  disabled,
  setOldAndNewPreviewValues,
}) => {
  const defaultValue = error && attemptedValue != null ? attemptedValue : setting.value;
  const [value, setValue] = useState(defaultValue);
  // Don't use rich editor for complex settings with newlines or
  // no schema bc it strips \n from the setting values making them invalid
  const blockRichEditor =
    setting.value.match(/\\n/) ||
    Object.keys(setting.json_schema).length === 0 ||
    setting.json_schema?.blockRichEditor;
  const [richEditor, setRichEditor] = useState(!blockRichEditor);
  const [validateJson, setValidateJson] = useState(false);
  const [jsonError, setJsonError] = useState("");
  const { currentUser } = useUserConfiguration();

  const subChange = (e, v) => {
    let newValue = v;
    if (e != null) {
      newValue = e.target.value;
    }
    onChange(null, newValue);
    setValue(newValue);
  };
  useEffect(() => {
    if (validateJson || jsonError) {
      try {
        JSON.parse(value);
        setJsonError(null);
      } catch (e) {
        setJsonError(e.toString());
      }
    }
  });

  return (
    <div className={style.noSemantic}>
      <div className={cc(["nds-typography", "margin--bottom--s"])}>
        <Row alignItems="center" justifyContent="end" gapSize="xs">
          {!blockRichEditor && (
            <>
              <Row.Item shrink>
                <Toggle
                  defaultActive={richEditor}
                  onChange={setRichEditor}
                  id="use_rich_editor_toggle"
                />
              </Row.Item>
              <Row.Item>
                <label // eslint-disable-line jsx-a11y/label-has-associated-control
                  htmlFor="use_rich_editor_toggle"
                  style={{ fontSize: "var(--font-size-m)" }}
                >
                  Use rich editor
                </label>
              </Row.Item>
            </>
          )}
          {currentUser.is_narmi && (
            <Row.Item shrink>
              <Button
                onClick={(evt) => {
                  evt.preventDefault();
                  setOldAndNewPreviewValues(setting.value, value);
                }}
                type="seconday"
                label="Preview Changes"
                disabled={disabled}
                testId="preview-button"
              />
            </Row.Item>
          )}
        </Row>
      </div>
      <div className="nds-typography">
        <Row>
          <Row.Item>
            {richEditor ? (
              <JsonInputRichEditor
                setting={setting}
                onChange={subChange}
                attemptedValue={attemptedValue}
                error={error}
                value={value}
                defaultValue={defaultValue}
              />
            ) : (
              <TextInput
                label={setting.human_facing_name}
                type="text"
                name="setting_value"
                id="setting_value"
                formatter={(text) => text.replace(/“/g, '"').replace(/”/g, '"')}
                onChange={subChange}
                onBlur={() => setValidateJson(true)}
                onFocus={() => setValidateJson(false)}
                multiline={setting.value.length > 50 || /[\r\n]/.exec(setting.value)}
                defaultValue={value}
                error={error || jsonError}
              />
            )}
          </Row.Item>
        </Row>
      </div>
    </div>
  );
};

export default JsonInput;
