// eslint-disable-next-line import/extensions
import jsonFromDocument from "../../../../../cerulean/src/JsonFromDocument";

// Until we support automated third party translations, we must restrict what FIs
// are able to do on their own in order to avoid operational issues.
//
// As of v2024.11, FIs get access to an English-only copy editor version
// of the editor when the "copyeditor" flag is enabled.
//
// The "multilingual" flag must be enabled to view and edit messages for non-English languages.
// Narmi internal users get full permissions.
const useTranslationPermissions = () => {
  const isNarmi = jsonFromDocument("current_user")?.is_narmi || false;
  const features = Object.entries(
    jsonFromDocument("institution_features", "null") ?? jsonFromDocument("features")
  )
    .filter(([, value]) => value)
    .map(([key]) => key);
  return {
    addLanguage: isNarmi,
    removeLanguage: isNarmi,
    statusChange: features.includes("multilingual") || isNarmi, // enable or disable a language
    editRaw: isNarmi,
    viewRaw: features.includes("multilingual") || isNarmi,
    editOtherMessage: features.includes("multilingual") || isNarmi,
    editEnglishMessage: features.includes("copyeditor") || isNarmi,
  };
};

export default useTranslationPermissions;
