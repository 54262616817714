import React, { useState } from "react";
import { ContentCard } from "@narmi/design_system";
import OpwProductDetailPage from "./OpwProductDetailPage";
import { useOpwContext } from "./OpwContext";


const OpwAddProductPage = () => {
  const [productCategory, setProductCategory] = useState("");
  const { hsaEnabled, iraEnabled, isBusiness } = useOpwContext();

  return (
    <>
      {productCategory === "" ? (
        <>
          <div
            className="fontSize--heading1 fontFamily--heading"
            style={{
              margin: "42.5px auto auto auto",
              height: "80px",
              width: "509px",
              textAlign: "center",
            }}
          >
            What type of product do you want to add?
          </div>
          <div
            className="nds-row-item whiteSpace--truncate"
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "40px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "12px",
                justifyContent: "space-between",
                height: "44px",
              }}
            >
              <ContentCard
                kind="interactive"
                onClick={() => {
                  setProductCategory("checking");
                }}
                paddingSize="none"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "12px 20px 12px 20px",
                  }}
                >
                  <div
                    style={{ color: "rgba(var(--theme-rgb-primary))" }}
                    className="whiteSpace--truncate fontWeight--semibold"
                  >
                    Checking
                  </div>
                </div>
              </ContentCard>
              <ContentCard
                kind="interactive"
                onClick={() => {
                  setProductCategory("savings");
                }}
                paddingSize="none"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "12px 20px 12px 20px",
                  }}
                >
                  <div
                    style={{ color: "rgba(var(--theme-rgb-primary))" }}
                    className="whiteSpace--truncate fontWeight--semibold"
                  >
                    Savings
                  </div>
                </div>
              </ContentCard>
              {!isBusiness && hsaEnabled && <ContentCard
                kind="interactive"
                onClick={() => {
                  setProductCategory("hsa");
                }}
                paddingSize="none"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "12px 20px 12px 20px",
                  }}
                >
                  <div
                    style={{ color: "rgba(var(--theme-rgb-primary))" }}
                    className="whiteSpace--truncate fontWeight--semibold"
                  >
                    HSA
                  </div>
                </div>
              </ContentCard>}
              {!isBusiness && iraEnabled && <ContentCard
                kind="interactive"
                onClick={() => {
                  setProductCategory("ira");
                }}
                paddingSize="none"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "12px 20px 12px 20px",
                  }}
                >
                  <div
                    style={{ color: "rgba(var(--theme-rgb-primary))" }}
                    className="whiteSpace--truncate fontWeight--semibold"
                  >
                    IRA
                  </div>
                </div>
              </ContentCard>}
              <ContentCard
                kind="interactive"
                onClick={() => {
                  setProductCategory("certificate_of_deposit");
                }}
                paddingSize="none"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "12px 20px 12px 20px",
                  }}
                >
                  <div
                    style={{ color: "rgba(var(--theme-rgb-primary))" }}
                    className="whiteSpace--truncate fontWeight--semibold"
                  >
                    Certificate of Deposit
                  </div>
                </div>
              </ContentCard>
              <ContentCard
                kind="interactive"
                onClick={() => {
                  setProductCategory("money_market");
                }}
                paddingSize="none"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "12px 20px 12px 20px",
                  }}
                >
                  <div
                    style={{ color: "rgba(var(--theme-rgb-primary))" }}
                    className="whiteSpace--truncate fontWeight--semibold"
                  >
                    Money Market
                  </div>
                </div>
              </ContentCard>
            </div>
          </div>
        </>
      ) : (
        <OpwProductDetailPage productCategory={productCategory} />
      )}
    </>
  );
};

export default OpwAddProductPage;
