import { FluentResource } from "@fluent/bundle";

export const GENERATED_FTL_PREFIX =
  "# DEBUG MODE: This FTL resource has been generated by `useFluentLocalization` in debug mode.\n";

export const createDebugFtlString = (defatulFtlString: string): string => {
  const debugResource = new FluentResource(defatulFtlString);

  // Terms, like `-term-end-user`, may not be referenced outside the FTL file.
  // They are not useful to modify or render while debugging. We only care about `getString` calls to IDs.
  const terms = debugResource.body.filter(({ id }) => id.startsWith("-"));

  // Set all message values to their id
  const messages = debugResource.body
    .filter(({ id }) => !id.startsWith("-"))
    .map(({ id, attributes }) => ({
      id,
      value: id,
      attributes,
    }));

  return [...terms, ...messages].reduce(
    // eslint-disable-next-line no-return-assign, no-param-reassign
    (ftlString, { id, value }) => (ftlString += `${id} = ${value}\n`),
    GENERATED_FTL_PREFIX,
  );
};
