// @ts-ignore
import React from "react";
import { Check, X } from "react-feather";
import ReactMarkdown from "react-markdown";
import { Button } from "@narmi/design_system";
import useTimeout from "../useTimeout";

const SuccessMessage = ({
  text,
  dismissMessage,
  creationTime,
  notificationType = "success",
}: {
  text: string;
  dismissMessage: () => void;
  creationTime: number;
  notificationType: string;
}) => {
  if (notificationType === "success") {
    useTimeout(dismissMessage, 5000 - (Date.now() - creationTime));
  }
  return (
    <div className="message-content success">
      <Check color="var(--color-successDark)" />
      <span
        className={notificationType === "success" ? "" : "margin--right--l"}
        data-testid="success-message"
      >
        <ReactMarkdown>{text}</ReactMarkdown>
      </span>
      {notificationType === "dismissible_success" && (
        <Button kind="plain" label={<X className="toast-x" />} onClick={dismissMessage} />
      )}
    </div>
  );
};

export default SuccessMessage;
