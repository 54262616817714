import React, { useCallback, useContext } from "react";
import ReactMarkdown from "react-markdown";
import { Button, DateInput, Dialog, RadioButtons, Row, TextInput } from "@narmi/design_system";

import { Link, useLocation } from "react-router-dom";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { CsrfTokenMiddleware, jsonFromDocument, NotificationContext } from "cerulean"; // eslint-disable-line import/no-unresolved
import mime from "mime";
import JsonInput from "./JsonInput";
import { ImageUploadInput } from "./ImageUploadInput";
import HistoryTable from "./HistoryTable";

const settingPreviewStyle = {
  backgroundColor: "RGB(var(--nds-white))",
  border: "1px solid RGB(var(--nds-lightest-grey))",
  padding: "10px",
  marginTop: "15px",
};

const SettingPreview = ({ valueTemplate, placeholders }) => {
  if (!valueTemplate) return null;
  const content = valueTemplate.replaceAll(
    /{([^}]*)}/g,
    (match, p1) => `<strong>${placeholders[p1]}</strong>`
  );
  return <div style={settingPreviewStyle} dangerouslySetInnerHTML={{ __html: content }} />;
};

const testSFTPConnection = ({ setIsLoading, setting, sendNotification, currentValue }) => {
  setIsLoading(true);
  const csrfToken = jsonFromDocument("csrf_token") || "CSRF-TOKEN-NOT-FOUND";
  fetch(`/institution_settings/${setting.name}/test_sftp_setting`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": csrfToken,
    },
    body: JSON.stringify({ setting_value: currentValue }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        sendNotification({ type: "negative", text: data.error });
      } else if(data.warning){
        sendNotification({ type: "info", text: data.warning})
      } else {
        sendNotification({ type: "success", text: data.message });
      }
      setIsLoading(false);
    });
};

const EditScreen = ({ setting, error, backPath, attemptedValue }) => {
  const [changed, setChanged] = React.useState(false);
  const [currentValue, setCurrentValue] = React.useState(
    error && attemptedValue != null ? attemptedValue : setting.value
  );
  const [currentNoteValue, setCurrentNoteValue] = React.useState("");
  const [isHistoryDisplayed, setIsHistoryDisplayed] = React.useState(false);
  const [imageIsValid, setImageIsValid] = React.useState(true);
  const [previewIsOpen, setPreviewIsOpen] = React.useState(false);
  const [oldPreviewValue, setOldPreviewValue] = React.useState("");
  const [newPreviewValue, setNewPreviewValue] = React.useState("");
  const { sendNotification } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = React.useState(false);

  const qp = new URLSearchParams(useLocation().search);

  const onChange = (e, v) => {
    let value = v;
    if (e != null) {
      value = e.target.value;
    }
    setCurrentValue(value);
    setChanged(value !== setting.value);
  };

  const onChangeNote = (e, v) => {
    let value = v;
    if (e != null) {
      value = e.target.value;
    }
    setCurrentNoteValue(value);
  };
  const setOldAndNewPreviewValues = (oldValue, newValue) => {
    setOldPreviewValue(oldValue);
    setNewPreviewValue(newValue);
    setPreviewIsOpen(true);
  };

  // if the presigned URL is blank fallback to old text field for URLs
  // presigned URL should be blank for FI without the feature flag on and for local dev
  const s3PresignedUrl = setting.constraints?.s3_presigned_url;
  const isS3Upload = setting.type === "ImageUploadSettingType" && s3PresignedUrl;

  const testSFTPConnectionCallback = useCallback(
    () => testSFTPConnection({ setIsLoading, setting, sendNotification, currentValue }),
    [setIsLoading, setting, sendNotification, currentValue]
  );

  const renderInput = () => {
    if (setting.type === "BooleanSettingType") {
      return <BooleanInput setting={setting} error={error} onChange={onChange} />;
    }
    if (setting.type === "ColorSettingType") {
      return (
        <ColorInput
          setting={setting}
          attemptedValue={attemptedValue}
          error={error}
          onChange={onChange}
        />
      );
    }
    if (setting.type === "DateTimeSettingType") {
      return (
        <SupportDateInput
          defaultValue={setting.value}
          attemptedValue={attemptedValue}
          onChange={(dateString) => {
            onChange(null, dateString);
          }}
          error={error}
        />
      );
    }
    if (
      ["JsonDictSettingType", "JsonArraySettingType", "JsonStringSettingType"].includes(
        setting.type
      )
    ) {
      return (
        <JsonInput
          previewIsOpen={previewIsOpen}
          setPreviewIsOpen={setPreviewIsOpen}
          setOldAndNewPreviewValues={setOldAndNewPreviewValues}
          onChange={onChange}
          attemptedValue={attemptedValue}
          error={error || null}
          setting={setting}
          disabled={!changed}
        />
      );
    }
    if (setting.type === "MonospaceMultilineJsonEditor") {
      // Multiline and Monospace
      return (
        <TextInput
          label={setting.human_facing_name}
          type="text"
          name="setting_value"
          id="setting_value"
          formatter={(text) => text.replace(/“/g, '"').replace(/”/g, '"')}
          onChange={onChange}
          multiline
          defaultValue={error && attemptedValue != null ? attemptedValue : setting.value}
          error={error || null}
          style={{ fontFamily: "monospace" }}
        />
      );
    }
    if (setting.type === "SFTPURLSettingType") {
      return (
        <TextInput
          label={setting.human_facing_name}
          type="text"
          name="setting_value"
          id="setting_value"
          formatter={(text) => text.replace(/“/g, '"').replace(/”/g, '"')}
          onChange={onChange}
          multiline={setting.value?.length > 50 || /[\r\n]/.exec(setting.value)}
          defaultValue={error && attemptedValue != null ? attemptedValue : setting.value}
          error={error || null}
          endContent={
            <Button
              type="button"
              kind="plain"
              label="Test SFTP Connection"
              onClick={testSFTPConnectionCallback}
              isLoading={isLoading}
            />
          }
        />
      );
    }
    if (isS3Upload) {
      return (
        <ImageUploadInput
          defaultValue={setting.value}
          attemptedValue={attemptedValue}
          constraints={setting.constraints}
          onChange={(newValue) => {
            setImageIsValid(!!newValue);
            onChange(null, newValue);
          }}
          error={error}
          backgroundColor={setting.background_color}
        />
      );
    }
    return (
      <TextInput
        label={setting.human_facing_name}
        type="text"
        name="setting_value"
        id="setting_value"
        formatter={(text) => text.replace(/“/g, '"').replace(/”/g, '"')}
        onChange={onChange}
        multiline={setting.value?.length > 50 || /[\r\n]/.exec(setting.value)}
        defaultValue={error && attemptedValue != null ? attemptedValue : setting.value}
        error={error || null}
      />
    );
  };

  const submitForm = (evt) => {
    evt.preventDefault();
    document.getElementById(`${setting.name}_submit`).submit();
  };
  const uploadToS3AndSubmit = (evt) => {
    evt.preventDefault();
    fetch(document.getElementById("image_value").src)
      .then((r) => r.blob())
      .then((blob) => {
        const ext = mime.getExtension(blob.type);
        // Create a new file from the one uploaded and give it a new name
        const uploadName = `${setting.name}-${Date.now()}.${ext}`;
        const uploadFile = new File([blob], uploadName, { type: blob.type });
        // create form data for upload to S3
        const formData = new FormData();
        Object.keys(s3PresignedUrl.fields).forEach((key) => {
          formData.append(key, s3PresignedUrl.fields[key]);
        });
        formData.append("file", uploadFile, uploadFile.name);
        formData.append("Content-Type", uploadFile.type);
        const postData = {
          method: "POST",
          body: formData,
        };
        fetch(s3PresignedUrl.url, postData).then((resp) => {
          // Set hidden setting value field with the response location that the image is posted
          document.getElementById("setting_value").value = resp.headers.get("location");
          // Submit form
          document.getElementById(`${setting.name}_submit`).submit();
        });
      });
  };

  const clearSettingAndSubmit = (evt) => {
    evt.preventDefault();
    document.getElementById("setting_value").value = "";
    document.getElementById(`${setting.name}_submit`).submit();
  };

  const isTemplateSetting = setting.type.endsWith("TemplateSettingType");

  const previewStyles = {
    variables: {
      light: {
        addedBackground: "#fff",
        removedBackground: "#fff",
        addedGutterBackground: "##fff",
        removedGutterBackground: "#f7f7f7",
        wordAddedBackground: "#37B374",
        wordRemovedBackground: "#D93B3B",
        emptyLineBackground: "#D93B3B",
      },
    },
  };

  return (
    <div className="column sixteen wide nds-typography">
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link style={{ marginTop: "14px" }} to={`${backPath}?${qp.toString()}`}>
          <span
            style={{ fontSize: "20px", cursor: "pointer", color: "#333333" }}
            className="narmi-icon-chevron-left"
          />
        </Link>
        <h2 style={{ marginTop: "0.5rem" }}>{`Edit ${setting.human_facing_name}`}</h2>
      </div>
      {setting.help_text ? (
        <div className="ui info message">
          <ReactMarkdown>{setting.help_text}</ReactMarkdown>
        </div>
      ) : null}
      {setting.default && !isTemplateSetting ? (
        <div className="ui message">
          <div className="header">Default Value</div>
          <p style={{ whiteSpace: "pre-wrap" }}>{setting.default}</p>
        </div>
      ) : null}
      <form id={`${setting.name}_submit`} method="POST" className={error || ""}>
        <Row alignItems="center" justifyContent="end" gapSize="xs">
          <Dialog
            isOpen={previewIsOpen}
            title="Preview"
            onUserDismiss={() => setPreviewIsOpen(false)}
            width="100%"
          >
            <div>
              <ReactDiffViewer
                oldValue={oldPreviewValue}
                newValue={newPreviewValue}
                splitView
                styles={previewStyles}
                compareMethod={DiffMethod.WORDS}
                leftTitle="Current Value"
                rightTitle="New Value"
              />
            </div>
          </Dialog>
        </Row>
        <br />
        {renderInput()}
        {isTemplateSetting && (
          <SettingPreview valueTemplate={currentValue} placeholders={setting.sample_settings} />
        )}
        <br />
        <TextInput
          label="Note"
          name="note_value"
          value={currentNoteValue}
          onChange={onChangeNote}
          id="note_value"
          required
        />
        <div className="margin--top--l">
          <Button
            onClick={!isS3Upload ? submitForm : uploadToS3AndSubmit}
            type="primary"
            disabled={!changed || !imageIsValid || currentNoteValue === ""}
            label="Save Changes"
          />
          {isS3Upload && !setting.default && (
            <Button
              style={{ marginLeft: "1rem" }}
              label="Clear Uploaded Image"
              kind="plain"
              onClick={clearSettingAndSubmit}
            />
          )}
        </div>
        <div className="margin--top--l">
          <Button
            label={isHistoryDisplayed ? "Hide change history" : "View change history"}
            onClick={() => {
              setIsHistoryDisplayed((currentDisplayValue) => !currentDisplayValue);
            }}
            kind="plain"
            type="button"
          />
        </div>
        {isHistoryDisplayed && (
          <HistoryTable
            settingName={setting.name}
            setPreviewIsOpen={setPreviewIsOpen}
            setOldAndNewPreviewValues={setOldAndNewPreviewValues}
            sendNotification={sendNotification}
            setIsHistoryDisplayed={setIsHistoryDisplayed}
          />
        )}
        <CsrfTokenMiddleware />
        <input type="hidden" name="setting_name" value={setting.name} />
      </form>
    </div>
  );
};

export default EditScreen;

const BooleanInput = ({ setting, error, onChange }) => (
  <div>
    <RadioButtons
      onChange={onChange}
      initialValue={setting.value}
      name="setting_value"
      options={{ True: "true", False: "false" }}
    />
    {error ? (
      <div style={{ color: "#d93b3b", display: "block", marginTop: "0.25rem" }}>
        {`Error: ${error}`}
      </div>
    ) : (
      ""
    )}
  </div>
);

const ColorInput = ({ setting, onChange, error, attemptedValue }) => {
  const [color, setColor] = React.useState(
    error && attemptedValue != null ? attemptedValue : setting.value
  );
  return (
    <div style={{ display: "flex", alignItems: "top" }}>
      <TextInput
        label="Hex Code"
        style={{ width: "280px" }}
        type="text"
        id="setting_value"
        name="setting_value"
        onChange={(e) => {
          setColor(e.target.value);
          onChange(e);
        }}
        value={color}
        error={error || null}
      />
      <span
        className="narmi-icon-solid-circle"
        style={{ marginLeft: "20px", color: `${color}`, fontSize: "50px" }}
      />
    </div>
  );
};

const SupportDateInput = ({ defaultValue, onChange, error, attemptedValue }) => {
  const [value, setValue] = React.useState(
    error && attemptedValue != null ? attemptedValue : defaultValue
  );

  return (
    <DateInput
      label="yyyy-mm-dd"
      altInput
      altFormat="Y-m-d"
      error={error}
      name="setting_value"
      id="setting_value"
      value={value}
      onChange={(dateString) => {
        setValue(dateString);
        onChange(null, dateString);
      }}
    />
  );
};
