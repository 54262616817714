/* eslint-disable no-param-reassign */
import React from "react";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import Product, {
  ALLOWED_CATEGORIES,
  ALL_APP_TYPES,
  OPTIONAL_FIELDS,
  REQUIRED_FIELDS,
} from "./OpwProduct";
import styles from "./styles/OpeningProductsWizard.module.css";

export function isValidEmpty(value) {
  if (!isEmpty(value)) {
    return false;
  }
  if (typeof value === "number" && !Number.isNaN(value)) {
    return false;
  }
  if (typeof value === "boolean") {
    return false;
  }
  return true;
}

export function validateCond(
  productForm,
  field,
  validCondition,
  errMsg = "error"
) {
  if (!validCondition) {
    productForm.errors[field] = errMsg;
    return false;
  }
  if (OPTIONAL_FIELDS.includes(field)) {
    if (
      field === "exclusive_application_types" &&
      isEqual(
        productForm.product.exclusive_application_types,
        Object.values(ALL_APP_TYPES)
      )
    ) {
      delete productForm.product.exclusive_application_types;
    }
    if (isValidEmpty(productForm.product[field])) {
      delete productForm[field];
    }
  }
  if (isEmpty(productForm.errors[field])) {
    delete productForm.errors[field];
  }
  return true;
}

export const OpwFormError = ({ error }) =>
  error ? (
    <div className={styles.opwInputError}>
      <div className="fontSize--s narmi-icon-x-circle margin--right--xxs" />
      {error}
    </div>
  ) : null;

class ProductForm {
  static emptyProductForm() {
    return {
      id: null,
      name: null,
      category: "checking",
      description: null,
      details: ["", "", ""],
      features: {},
      max_count: null,
      minimum_balance: "0",
      options: {},
      public: false,
      recommended: false,
      required: false,
      hidden: false,
      iraContributionOptions: {},
      metadata: {},
      display_order: null,
    };
  }

  constructor(props) {
    this.product = props.product || new Product();
    this.errors = props.errors || {};
    this.isValid = false;
    this.note = props.note || "";
    this.otherProductIds = props.otherProductIds || new Set();

    this.validationMap = {
      id: {
        type: "string",
        validate: () =>
          validateCond(
            this,
            "id",
            !this.otherProductIds.has(this.product.id),
            "Must be unique."
          ),
      },
      name: {
        type: "string",
        validate: () =>
          validateCond(
            this,
            "name",
            this.product.name?.length < 60,
            "Exceeds character limit."
          )
      },
      description: {
        type: "string",
        validate: () => 
          validateCond(
            this,
            "description",
            this.product.description?.length < 120,
            "Exceeds character limit."
          ),
      },
      category: {
        type: "string",
        validate: () =>
          validateCond(
            this,
            "category",
            ALLOWED_CATEGORIES.includes(this.product.category),
            "Must be a valid category."
          ),
      },
      minimum_balance: {
        type: "string",
        validate: () => {
          const isANum = validateCond(
            this,
            "minimum_balance",
            Number.isSafeInteger(+this.product.minimum_balance) &&
              !Number.isNaN(+this.product.minimum_balance) &&
              +this.product.minimum_balance > -1,
            "Must be a whole positive number."
          );
          if (isANum) {
            this.product.minimum_balance =
              this.product.minimum_balance.toString();
          }
          return isANum;
        },
      },
      maximum_balance: {
        type: "string",
        validate: () => {
          if (this.product.maximum_balance === undefined) {
            delete this.errors.maximum_balance;
            delete this.product.maximum_balance;
            return true;
          }
          let greaterThanMin = true;
          const isANum = validateCond(
            this,
            "maximum_balance",
            Number.isSafeInteger(+this.product.maximum_balance) &&
              !Number.isNaN(+this.product.maximum_balance),
            "Must be a whole number."
          );
          if (!isANum) {
            return isANum;
          }
          if (this.validationMap.minimum_balance.validate()) {
            greaterThanMin = validateCond(
              this,
              "maximum_balance",
              +this.product.minimum_balance <= +this.product.maximum_balance,
              "Maximum balance must be greater than or equal to minimum balance"
            );
          }
          if (isANum && greaterThanMin) {
            this.product.maximum_balance =
              this.product.maximum_balance.toString();
          }
          return isANum && greaterThanMin;
        },
      },
      details: {
        type: "object", // array
        validate: () => {
          const ret = [];
          this.product.details.forEach((d) => {
            if (String(d)?.trim()) {
              ret.push(String(d)?.trim());
            }
          });
          if (
            ret.length === 0 &&
            this.product.category !== "certificate_of_deposit"
          ) {
            this.errors.details = "Must include at least one detail field.";
            return false;
          }
          delete this.errors.details;
          return true;
        },
      },
      features: {
        type: "object",
        validate: () => {
          if (!this.canHaveOverdraftProtection()) {
            delete this.product.features?.overdraft;
          }
          return true;
        },
      },
      max_count: {
        type: "string",
        validate: () => {
          if (!Number.isNaN(+this.product.max_count)) {
            this.product.max_count = +this.product.max_count;
            if (+this.product.max_count < 0) {
              this.errors.max_count = "Must be a positive number.";
              return false;
            }
            delete this.errors.max_count;
            return true;
          }
          if (isValidEmpty(this.product.max_count)) {
            delete this.product.max_count;
            delete this.errors.max_count;
            return true;
          }
          this.errors.max_count = "Must be a number.";
          return false;
        },
      },
      metadata: {
        type: "object",
        validate: () => {
          delete this.errors.metadata;
          try {
            if (typeof this.product.metadata === "string") {
              JSON.parse(this.product.metadata);
            }
          } catch {
            this.errors.metadata = "Metadata must be a valid JSON object.";
            return false;
          }
          return true;
        },
      },
      hidden: {
        type: "boolean",
        validate: () => true,
      },
      options: {
        type: "boolean",
        validate: () => {
          if (!this.canHaveCourtesyPay()) {
            delete this.product.options?.courtesy_pay;
          }
          if (!this.canHaveDebitCard()) {
            delete this.product.options?.debit_card;
          }
          if (!this.canHaveCustomCards()) {
            delete this.product.options?.card_selection;
          }
          return true;
        },
      },
      public: {
        type: "boolean",
        validate: () =>
          validateCond(
            this,
            "public",
            true,
            "Invalid public value. Contact support."
          ),
      },
      exclusive_application_types: {
        type: Array,
        validate: () =>
          validateCond(
            this,
            "exclusive_application_types",
            this.product.exclusive_application_types?.every((t) =>
              Object.values(ALL_APP_TYPES).includes(t)
            ),
            "At least one application type must be selected"
          ),
      },
      required: {
        type: "boolean",
        validate: () => true,
      },
      require_debit_card: {
        type: "boolean",
        validate: () => true,
      },
      enable_debit_card_by_default: {
        type: "boolean",
        validate: () => true,
      },
      recommended: {
        type: "boolean",
        validate: () => true,
      },
      offer_branded_cards: {
        type: "boolean",
        validate: () =>
          validateCond(
            this,
            "offer_branded_cards",
            this.product.offer_branded_cards ? this.product.options?.card_images.length > 1 : true,
            "Upload at least two branded cards."
          ),
      },
      disclosures: {
        type: "object",
        validate: () => {
          const fieldRequired = "Must be filled in.";
          this.errors.disclosures = {};
          for (let i = 0; i < this.product.disclosures.length; i += 1) {
            if (isEmpty(this.errors.disclosures[i])) {
              this.errors.disclosures[i] = {};
            }
            if (
              this.product.disclosures[i].links &&
              this.product.disclosures[i].links?.length > 0
            ) {
              if (
                isEmpty(this.errors.disclosures[i].links) &&
                !isEmpty(this.product.disclosures[i].links)
              ) {
                this.errors.disclosures[i].links = {};
              }

              for (
                let j = 0;
                j < this.product.disclosures[i].links?.length;
                j += 1
              ) {
                if (isEmpty(this.errors.disclosures[i].links[j])) {
                  this.errors.disclosures[i].links[j] = {};
                }
                if (!this.product.disclosures[i].links[j].text) {
                  this.errors.disclosures[i].links[j].text = fieldRequired;
                } else {
                  delete this.errors.disclosures[i].links[j].text;
                }
                if (!this.product.disclosures[i].links[j].url) {
                  this.errors.disclosures[i].links[j].url = fieldRequired;
                } else {
                  delete this.errors.disclosures[i].links[j].url;
                }
                if (isEmpty(this.errors.disclosures[i].links[j])) {
                  delete this.errors.disclosures[i].links[j];
                }
              }
              if (isEmpty(this.errors.disclosures[i].links)) {
                delete this.errors.disclosures[i].links;
              }
            }
            if (isEmpty(this.errors.disclosures[i])) {
              delete this.errors.disclosures[i];
            }
          }
          if (isEmpty(this.errors.disclosures)) {
            delete this.errors.disclosures;
          }
          return isEmpty(this.errors.disclosures);
        },
      },
      display_order: {
        type: "object",
        validate: () =>
          validateCond(
            this,
            "display_order",
            true,
            "Invalid display order value. Contact support."
          ),
      },
      note: {
        type: "string",
        validate: () => {
          if (isEmpty(this.note)) {
            this.errors.note = "Must be filled in.";
            return false;
          }
          delete this.errors.note;
          return true;
        },
      },
      monthly_service_fee: {
        type: "string",
        validate: () => true,
      },
      more_details_url: {
        type: "string",
        validate: () => {
          if (!this.product.more_details_url) {
            delete this.errors.more_details_url;
            return true;
          }
          const pattern = new RegExp(
            // Broken up for readability. Single line regex for testing:
            // /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/[\w.-]*)*(#[\w-]*)?\/?$/
            // Also testable on https://regex101.com/r/9AzEpW/1
            // Does not support query strings
            [
              /^(https?:\/\/)/, // protocol
              /([\da-z.-]+)/, // domain
              /\./, // period between domain and suffix
              /([a-z.]{2,6})/, // domain suffix
              /(\/[\w.-]*)*/, // path (optional, as many as needed)
              /(#[\w-]*)?/, // anchor (only once)
              /\/?$/, // single trailing slash at the end of string
            ]
              .map((r) => r.source) // combine all regex
              .join(""),
            "i"
          );
          const isValidUrl = pattern.test(this.product.more_details_url);
          if(!isValidUrl) {
            if(this.product.more_details_url.includes("https://")){
              this.errors.more_details_url = "Must be a valid URL.";
            }
            else {
              this.errors.more_details_url = "Please ensure the format of the URL starts with https://.";
            }
          }
          else delete this.errors.more_details_url;
          return isValidUrl;
        },
      },
    };
    this.canHaveCourtesyPay = () => ["checking", "savings"].includes(this.product.category);
    this.canHaveDebitCard = () => ["checking", "savings", "hsa"].includes(this.product.category);
    this.canHaveCustomCards = () => ["checking", "savings"].includes(this.product.category);
    this.canHaveOverdraftProtection = () =>
      !["certificate_of_deposit"].includes(this.product.category);
  }

  cleanupDisclosures() {
    for (let i = 0; i < this.product.disclosures.length; i += 1) {
      if (this.product.disclosures[i].markdown === "") {
        delete this.product.disclosures[i].markdown;
      }
      if (this.product.disclosures[i].checkbox_text === "") {
        delete this.product.disclosures[i].checkbox_text;
      }
      for (let j = 0; j < this.product.disclosures[i].links?.length; j += 1) {
        if (this.product.disclosures[i].links[j].text === "") {
          delete this.product.disclosures[i].links[j].text;
        }
        if (this.product.disclosures[i].links[j].url === "") {
          delete this.product.disclosures[i].links[j].url;
        }
        if (isEmpty(this.product.disclosures[i].links[j])) {
          this.product.disclosures[i].links.splice(j, 1);
        }
      }
      if (isEmpty(this.product.disclosures[i].links)) {
        delete this.product.disclosures[i].links;
      }
      if (isEmpty(this.product.disclosures[i])) {
        this.product.disclosures.splice(i, 1);
      }
    }
    if (isEmpty(this.product.disclosures)) {
      delete this.product.disclosures;
    }
  }

  // eslint-disable-next-line consistent-return, class-methods-use-this
  validate(...args) {
    let isValid = true;
    const validateAll = () => {
      OPTIONAL_FIELDS.forEach((f) => {
        if (isValidEmpty(this.product[f])) {
          delete this.product[f];
        }
      });
      REQUIRED_FIELDS.forEach((f) => {
        if (isValidEmpty(this.product[f])) {
          this.errors[f] = "Must be filled in.";
          isValid = false;
        }
      });
      Object.values(this.validationMap).forEach((v) => {
        if (!v.validate()) {
          isValid = false;
        }
      });
      this.isValid = isValid;
      if (isValid) {
        this.errors = {};
      }
      return this.isValid;
    };

    const validateFields = (field) => {
      if (
        OPTIONAL_FIELDS.includes(field) &&
        (this.product[field] === null || this.product[field] === "")
      ) {
        delete this.product[field];
      }
      if (
        REQUIRED_FIELDS.includes(field) &&
        isValidEmpty(this.product[field])
      ) {
        this.errors[field] = "Must be filled in.";
        isValid = false;
      }
      this.isValid = isValid && this.validationMap[field]?.validate();
      if (this.isValid) {
        delete this.errors[field];
        delete this.errors.submitErrors;
      }
      return this.isValid;
    };

    if (arguments.length === 0) {
      return validateAll();
    }
    if (arguments.length === 1) {
      return validateFields(args[0]);
    }
  }
}

export default ProductForm;
