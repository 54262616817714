import { jsonFromDocument } from "cerulean"; // eslint-disable-line import/no-unresolved

const useUserConfiguration = () => {
  const currentUser = jsonFromDocument("current_user") || {};

  return {
    currentUser,
  };
};

export default useUserConfiguration;
