import React, { createContext, useContext, useState, ReactNode, useMemo, useCallback } from "react";

type GliaVisibilityContextType = {
  registerComponent: (id: string, isVisible: boolean) => void;
  unregisterComponent: (id: string) => void;
  isGliaVisible: boolean;
};

const GliaVisibilityContext = createContext<GliaVisibilityContextType>({
  isGliaVisible: true,
  registerComponent: () => {},
  unregisterComponent: () => {},
});

interface GliaVisibilityProviderProps {
  children: ReactNode;
}

export const GliaVisibilityProvider = ({ children }: GliaVisibilityProviderProps) => {
  const [componentVisibilities, setComponentVisibilities] = useState<Record<string, boolean>>({});

  const registerComponent = useCallback((id: string, isVisible: boolean) => {
    setComponentVisibilities((prev) => ({ ...prev, [id]: isVisible }));
  }, []);

  const unregisterComponent = useCallback((id: string) => {
    setComponentVisibilities((prev) => {
      const updated = { ...prev };
      delete updated[id];
      return updated;
    });
  }, []);

  const isGliaVisible = useMemo(
    () => Object.values(componentVisibilities).every((visible) => visible),
    [componentVisibilities]
  );
  const value = useMemo(
    () => ({ registerComponent, unregisterComponent, isGliaVisible }),
    [registerComponent, unregisterComponent, isGliaVisible]
  );

  return <GliaVisibilityContext.Provider value={value}>{children}</GliaVisibilityContext.Provider>;
};

export const useGliaVisibilityContext = () => useContext(GliaVisibilityContext);
