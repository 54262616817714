import { FluentParser, FluentSerializer } from "@fluent/syntax";
import * as Sentry from "@sentry/react";

const Parser = new FluentParser({ withSpans: false });
const Serializer = new FluentSerializer();

const ALLOWED_FTL_NODES = ["Message", "Term"];

/**
 * IMPORTANT FLUENT TERMS
 * ----------------------
 * `message`: an ID/value pair (`try-hotdogs = Try our hot dogs!`)
 * `id`: the message key (`try-hotdogs`)
 * `value`: the message value (`Try our hot dogs!`)
 * `term`: prefixed by "-", a message only readable within the FTL file
 */

/**
 * Returns a list of locale codes used accross all products.
 * @param {object} manifest parsed manifest json
 * @returns {Array} list of all locale codes supported across products
 */
export const getAllSupportedLocales = (manifest) =>
  [
    ...new Set(Object.values(manifest).flatMap((p) => Object.keys(p)))
  ].filter((l) => l !== "en")

/**
 * @param {String} localeCode
 * @param {Object} state current state from reducer
 * @param {Object} manifest object from manifest.json
 * @returns {Boolean} true if the given locale is in the s3 manifest
 */
export const hasSavedLocale = (product, localeCode, manifest) =>
  product in manifest && localeCode in manifest[product];

/**
 * Helper to determine if a locale has been saved and enabled
 * @param {String} localeCode
 * @param {Object} productCode current state from reducer
 * @param {Object} manifest object from manifest.json
 * @returns {Boolean} isEnabled
 */
export const isLocaleEnabled = (localeCode, productCode, manifest) =>
  manifest[productCode]?.[localeCode]?.state === "enabled";

/**
 * Convert an ftl string into an object we can
 * use in `state.locales[locale].messages`
 *
 * @param {String} ftlString file content of an FTL
 * @returns {Object} { messageId: message, messageId: message }
 */
export const ftlToLocaleData = (ftlString) => {
  const errorMessage = "[ftlToLocaleData] failed to serialize messages";

  const result = Parser.parse(ftlString)
    .body.filter(({ type }) => ALLOWED_FTL_NODES.includes(type))
    .reduce((messages, message) => {
      const messageId = message.type === "Term" ? `-${message.id.name}` : message.id.name;
      try {
        const serialized = Serializer.serializeEntry(message);
        // eslint-disable-next-line no-param-reassign
        messages[messageId] = serialized.trimEnd();
      } catch (err) {
        Sentry.captureException(errorMessage);
      }
      return messages;
    }, {});

  if (Object.entries(result).length < 1) {
    Sentry.captureException(errorMessage);
  }

  return result;
};

/**
 * Converts a locale (object containing id/msg fluent message entries)
 * to a full FTL file string
 *
 * @param {Object} localeData { '{fluent id}': '{fluent message}', ...}
 * @returns {String} full ftl file string for the given locale
 */
export const localeDataToFtl = (localeData) =>
  Object.values(localeData.messages).join("\n");

/**
 * @param {String} message a single FTL message (full message including ID)
 * @returns {Object} pertinent data from message (validity, id, vars)
 */
export const getMessageData = (message) => {
  let result = {
    isJunk: false,
    id: "",
    vars: [],
  };
  const parsedMessage = Parser.parse(message).body[0];

  if (parsedMessage.type === "Junk") {
    result = { ...result, isJunk: true };
  } else {
    const { id } = parsedMessage;

    // Placeables can appear in the message, function expressions, and selectors.
    // We can look for their references based on the `$` prefix:
    // match `{ $placeable }`, `FUNCTION($placeable)`, `{$placeable}`
    const vars = [
      ...new Set(message.match(new RegExp(/\$[a-zA-Z]{2,}([^\s)}])/gm))),
    ]
      .map((v) => v.replace("$", ""))
      .sort(); // normalize across langs that may have different word order

    result = {
      ...result,
      id: id.name,
      vars,
    };
  }

  return result;
};

/**
 * Validates a fluent message against its previous value
 * @param {String} originalMsg the message _before_ editing
 * @param {String} newMsg the message _after_ editing
 * @param {String} defaultMsg the value of the message in our default FTL
 * @returns {Object} validity and reason message
 */
export const validateMessage = (originalMsg, newMsg, defaultMsg) => {
  let result = { isValid: true };
  const originalData = getMessageData(originalMsg);
  const newData = getMessageData(newMsg);
  const newVars = [...new Set(newData.vars)];
  const defaultData = getMessageData(defaultMsg);
  const defaultVars = [...new Set(defaultData.vars)];

  // users can remove vars and reuse vars within a message - BUT -
  // They can NOT add vars that do not appear in our default FTL for this message
  const isVarChangeValid = newVars.every((v) => defaultVars.includes(v));

  if (newData.isJunk) {
    result = {
      isValid: false,
      reason: "Invalid Fluent syntax",
    };
  }

  if (!newData.isJunk && !isVarChangeValid) {
    result = {
      isValid: false,
      reason: `${newData.id} has an invalid variable change.
      \n\nValid variables: ${defaultVars.join(",")}
      \n\nReceived: ${newVars.join(",")}`,
    };
  }

  if (!newData.isJunk && originalData.id !== newData.id) {
    result = {
      isValid: false,
      reason: "ID does not match original. Please do not modify message IDs.",
    };
  }

  return result;
};

/**
 * Validate an entire FTL file string. Useful for raw editing.
 *
 * @param {String} originalFtl
 * @param {String} newFtl
 * @param {String} defaultFtl
 * @param {Object} opts
 * @returns {Object} validity and reason message
 */
export const validateFtl = (
  originalFtl,
  newFtl,
  defaultFtl,
  opts = { allowAddMessage: false, allowRemoveMessage: false }
) => {
  let result = { isValid: true };
  const originalMessages = Object.values(ftlToLocaleData(originalFtl));
  const newMessages = Object.values(ftlToLocaleData(newFtl));
  const defaultMessages = Object.values(ftlToLocaleData(defaultFtl));

  if (
    !opts.allowRemoveMessage &&
    newMessages.length < originalMessages.length
  ) {
    result = {
      isValid: false,
      reason: "A message has been removed. Please do not remove messages.",
    };
  }

  if (!opts.allowAddMessage && newMessages.length > originalMessages.length) {
    result = {
      isValid: false,
      reason: "A message has been added. Please do not add new messages.",
    };
  }

  // validate every message; fail validation on first error encountered
  if (newMessages.length === originalMessages.length) {
    newMessages.every((message, i) => {
      const { isValid, reason } = validateMessage(
        originalMessages[i],
        message,
        defaultMessages[i]
      );
      if (!isValid) {
        result = { isValid, reason };
        return false;
      }
      return true;
    });
  }

  return result;
};
