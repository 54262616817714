/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import { Row, Button } from "@narmi/design_system";

export const getReadableValue = (msg) =>
  msg
    .replace(/^[a-zA-Z-0-9]+\s=\s/, "") // take only the value
    .replace(/^\n/, "") // trim leading newline
    .replace(/\n|\r/, ""); // remove newline from multiline messages

const MessageDisplay = ({
  id,
  message,
  onEdit = () => {},
  isReadOnly = false,
}) => (
  <Row alignItems="center">
    <Row.Item>
      <Row>
        <Row.Item shrink>
          <div style={{ minWidth: "360px" }}>
            <code className="fontWeight--bold fontColor--heading fontSize--s">{id}</code>
          </div>
        </Row.Item>
        <Row.Item>
          <div style={{ maxWidth: "60ch" }}>
            <span className="fontColor--secondary fontSize--s">
              {getReadableValue(message)}
            </span>
          </div>
        </Row.Item>
      </Row>
    </Row.Item>
    <Row.Item shrink>
      {isReadOnly ? (
        <span className="narmi-icon-lock fontColor--hint" />
      ) : (
        <Button kind="plain" label="Edit" startIcon="edit" onClick={onEdit} />
      )}
    </Row.Item>
  </Row>
);

// const MessageDisplay = ({ id, message, onEdit }) => {
MessageDisplay.propTypes = {
  /** fluent message id */
  id: PropTypes.string.isRequired,
  /** full fluent message */
  message: PropTypes.string.isRequired,
  /** callback fired when user clicks the "Edit" button */
  onEdit: PropTypes.func,
  /** true if the locale these messages belong to is read only */
  isReadOnly: PropTypes.bool,
};

export default MessageDisplay;
