const TITLE_REFORMAT = {
  ach: "ACH",
  "ach information": "ACH information",
  "institution information": "Institution information",
  "refer a friend": "Refer a friend",
  "digital account opening": "Account Opening",
};

const hasReformatTitle = (rawTitle) => Object.keys(TITLE_REFORMAT).includes(rawTitle);

const getTitleDisplay = (rawTitle) => TITLE_REFORMAT[rawTitle] || rawTitle;

export { getTitleDisplay, hasReformatTitle };
