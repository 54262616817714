// Based off of c4f22084d289b45537c81330d1c9148dc3639b24 January 10th

import React from "react";
import ReactMarkdown from "react-markdown";
import Filters from "byzantine/src/filters";
import { getCategories } from "byzantine/src/Product";
import { Tabs } from "@narmi/design_system";
import { ProductCard } from "cerulean";

export const DisplayMarkdown = ({ markdown }) => (
  <div style={{ margin: "0px" }}>
    <ReactMarkdown className="markdown" linkTarget="_blank">
      {markdown}
    </ReactMarkdown>
  </div>
);

const CHECKING = "checking";
const RECOMMENDED = "recommended";

const BAOAccountSelectionFormField = ({
  businessProducts,
  categorySelected = null,
  onChange = () => {},
}) => {
  const selectedProducts = [];
  // # todo: v2 change this to be filtered based upon certain account type
  // const availableProducts = modifyingProducts.filter((p) =>
  //   isVisible(p, application.organization.type));
  const availableProducts = businessProducts.filter((p) => p.public);
  const categories = getCategories(availableProducts);
  const categorySelectedIndex = categories.indexOf(categorySelected);
  const isCD = (category) => category === "certificate_of_deposit";
  const productsForCategory = (category) =>
    availableProducts.filter((p) =>
      category === RECOMMENDED ? p.recommended : p.category === category
    );
  const productIsSelected = (p) =>
    selectedProducts.map((sp) => sp.id).includes(p.id);
  // takes a Product returns nothing
  const handleClick = (product) => {
    const updated = [...selectedProducts];
    const productIndex = updated.findIndex((p) => p.id === product.id);
    if (productIndex === -1) {
      // don't allow an applicant to select multiple checking products
      if (product.category === CHECKING) {
        const checkingProductIndex = updated.findIndex(
          (p) => p.category === CHECKING
        );
        if (
          checkingProductIndex !== -1 &&
          updated[checkingProductIndex].id !== product.id
        ) {
          updated.splice(checkingProductIndex, 1);
        }
      }
      updated.push(product);
    } else updated.splice(productIndex, 1);
    onChange(updated);
  };

  return (
    <div className="large">
      <div className="account-selection-form" style={{ marginTop: "4px" }}>
        <Tabs
          defaultSelectedIndex={
            categorySelectedIndex !== -1 ? categorySelectedIndex : 0
          }
        >
          <Tabs.List>
            {categories.map((category) => (
              <Tabs.Tab
                key={`${category}-list`}
                label={isCD(category) ? "CDs" : Filters.humanize(category)}
                tabId={category}
              />
            ))}
          </Tabs.List>
          {categories.map((category) => (
            <Tabs.Panel key={`${category}-panel`} tabId={category}>
              <div
                style={{ marginTop: "25px" }}
                className="margin--bottom--xxl"
              >
                <div className="product-card-container">
                  {productsForCategory(category).map((p) => (
                    <ProductCard
                      key={p.id}
                      product={p}
                      isSelected={productIsSelected(p)}
                      onClick={() => handleClick(p)}
                      hideRecommendedTag={category === RECOMMENDED}
                    />
                  ))}
                </div>
              </div>
            </Tabs.Panel>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default BAOAccountSelectionFormField;
