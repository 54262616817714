export default class Product {
  constructor(props) {
    if (props) Object.assign(this, props);
  }

  isVisible(applicationType) {
    if (this.hidden) return false;
    if (this.exclusive_application_types)
      return this.exclusive_application_types.includes(applicationType);
    return true;
  }
}

const RECOMMENDED = "recommended";

// the tab ordering rank on the Account Selection page
export const CategoryRank = {
  CHECKING: 0,
  SAVINGS: 1,
  MONEY_MARKET: 2,
  CERTIFICATE_OF_DEPOSIT: 3,
  HSA: 4,
  IRA: 5,
};

/**
 * Get unique categories from an array of Products
 * "checking" category is always first in the return value if present
 * @param products
 * @returns array of categories as strings
 */

export const getCategories = (products) => {
  const uniqueCategories = Array.from(new Set(products.map((p) => p.category)));
  const sortedCategories = uniqueCategories.sort(
    (a, b) => CategoryRank[a.toUpperCase()] - CategoryRank[b.toUpperCase()],
  );
  return products.some((p) => p.recommended)
    ? [RECOMMENDED, ...sortedCategories]
    : sortedCategories;
};
