import React from "react";
import { Button } from "@narmi/design_system";

const HistoryRow = ({ settingHistory, previousSettingHistory, setOldAndNewPreviewValues }) => (
  <tr style={{ borderTop: "1px solid rgb(var(--border-color))" }}>
    <td style={{ width: "100px" }}>{new Date(settingHistory.timestamp).toLocaleDateString()}</td>
    <td style={{ maxWidth: "400px", wordWrap: "break-word" }}>{settingHistory.previous_value}</td>
    <td>
      <Button
        type="button"
        kind="plain"
        onClick={() => {
          setOldAndNewPreviewValues(
            previousSettingHistory.previous_value,
            settingHistory.previous_value
          );
        }}
      >
        View changes
      </Button>
    </td>

    <td>{settingHistory.user}</td>
    <td style={{ maxWidth: "200px", wordWrap: "break-word" }}>{settingHistory.note_value}</td>
  </tr>
);

const HistoryTable = ({
  settingName,
  setOldAndNewPreviewValues,
  sendNotification,
  setIsHistoryDisplayed,
}) => {
  const [history, setHistory] = React.useState([]);
  const fetchHistory = async () => {
    const response = await fetch(`/audit_logs/setting/${settingName}`);
    try {
      const data = await response.json();
      setHistory(data.audits);
    } catch {
      sendNotification({ type: "negative", text: "Failed to fetch setting history" });
      setIsHistoryDisplayed(false);
    }
  };

  React.useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <table className="ui table" style={{ borderCollapse: "collapse" }}>
      <thead>
        <tr>
          <th>DATE</th>
          <th>VALUE</th>
          <th>{null}</th>
          <th>USER</th>
          <th>NOTE</th>
        </tr>
      </thead>
      <tbody>
        {history.map((settingHistory, idx) => (
          <HistoryRow
            key={settingHistory.timestamp}
            settingHistory={settingHistory}
            previousSettingHistory={idx < history.length - 1 ? history[idx + 1] : {}}
            setOldAndNewPreviewValues={setOldAndNewPreviewValues}
          />
        ))}
      </tbody>
    </table>
  );
};

export default HistoryTable;
